import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";

import "../styles/blocks/carousel.scss";

const splide_selectors = window["splide_selectors"] || [];

window.addEventListener("load", () => {
  if (window["splide_script_has_run"]) return;

  for (const selector of splide_selectors) {
    new Splide(selector, {
      type: "loop",
      rewind: true,
      gap: "1em",
      perPage: 3,

      breakpoints: {
        960: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      },
    }).mount();
  }

  window["splide_script_has_run"] = true;
});
